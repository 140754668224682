<template>
  <div class="payment-container">
    <div class="header">
      {{ $t('informationCompletion') }}
    </div>

    <div class="completed-container">
      <img
        width="75px"
        height="75px"
        :src="require('@/assets/imgs/completedIcon.png')"
      >
      <div class="space title">{{ $t('completeInfoTitle') }}</div>
      <div class="space">{{ $t('completeInfoDesc') }}</div>
    </div>

    <div class="footer fu-fixed">
      <Button @click="close">
        {{ $t('close') }}
      </Button>
    </div>

  </div>
</template>

<script>

import { Button } from 'vant'
import dao from '@/utils/dao'
export default {
  name: 'Payment',
  components: {
    Button
  },
  data() {
    return {

    }
  },
  computed: {
  },
  activated() {
    // const { token } = this.$route.query
  },
  methods: {
    close() {
      const token = dao.get('inspectionToken')

      this.$router.push('/inspection/orderDetail/?token=' + token)
    }
  }
}
</script>

  <style lang="scss" scoped>
  .payment-container {
    background-color: #FFFFFF;

    .hint {
      font-size: 16px;
      margin: 10px 0px 5px 0px;
    }

  .header {
    padding: 0 20px;
    height: 50px;
    line-height: 45px;
    background-color: #D90109;
    font-size: 0.4rem;
    text-align: center;
    color: white;
  }

  .completed-container {
    margin-top: 150px;
    font-size: 15px;
    padding: 12px 50px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .space {
      margin-top: 20px;
    }

    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .order-info {
      font-size: 17px;
      background-color: #f2f2f2;
      border-radius: 12px;
      padding: 10px 13px;
      width: 100%;
      margin-bottom: 20px;

      span {
        font-weight: bold
      }
    }
  }

    .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 40px;
  background: #fff;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  .van-button {
    box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    background: #C82A21;
    width: 100%;
    overflow: hidden;
    .van-button__text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #FFFFFF;
    }
  }

  .van-button + .van-button {
    margin-left: 10px;
  }
}

  .logo {
    text-align: center;
  }

  }

  </style>

